import React from 'react'
import { Layout, Row, Col, Button } from 'antd'
const { Footer } = Layout
import Img from "gatsby-image";

// @ts-ignore
import styles from './yourdata.module.scss';
import { StaticQuery, graphql, Link } from 'gatsby';

interface YourDataProps {
    topVisual: boolean
    moreButton: boolean
    images: any
}

export class YourData extends React.Component<YourDataProps, any> {

    constructor(props) {
        super(props);

        this.renderTopVisual = this.renderTopVisual.bind(this);
        this.renderMoreButton = this.renderMoreButton.bind(this);
    }

    renderTopVisual() {
        if (this.props.topVisual) {
            return (
                <div style={{ marginBottom: -10, minWidth: 296 }}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 278" preserveAspectRatio="none">
                        <path style={{ fill: "#007BFF" }} d="M0,145.8c2502.85-339,1634.584,120,1920,120v21H0Z" transform="translate(0 0.009)" />
                    </svg>
                </div>
            );
        }
    }

    renderMoreButton() {
        if (this.props.moreButton) {
            return (
                <div className={styles.buttoncontainer}>
                    <Link to="/personvern"><Button type="default" size="large" className={styles.buttonLargeDefault} {...{ "aria-label": "Les mer" }}>Les mer</Button></Link>
                </div>
            )
        }
    }

    render() {

        return (
            <div className={styles.section}>
                {this.renderTopVisual()}
                <div className={styles.introcontainer}>
                    <div className={this.props.topVisual ? styles.push : styles.nopush}>
                        <Row className={styles.container} justify="space-around" type="flex" align="middle" >
                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                <div style={{ marginLeft: "auto", marginRight: "auto", width: "auto", padding: 44, maxWidth: 538 }}>
                                    <Img title="Dine data" fluid={this.props.images.home.childImageSharp.fluid} className={styles.image} alt="Dine data" />
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                <div className={styles.header}>Ditt hjem. <br /> Dine data.</div>
                                <div className={styles.introtext}>
                                    Hjemmet ditt er personlig, og strømforbruket ditt er privat. Vi tar personvernet ditt på alvor og mener at din data skal være din&nbsp;data.
                            </div>
                                {this.renderMoreButton()}
                            </Col>
                        </Row>
                    </div>

                </div>
                <div style={{ marginTop: -1, float: "left", width: "100%", minWidth: 296 }}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1918.833 190.059" preserveAspectRatio="none">
                        <path style={{ fill: "#007BFF" }} d="M1920,580.385H1.459s-.292,3.208-.292,15.895H509.84c242.517,0,1359.963-6.157,1149.84,137.5-72.376,49.441,87.551,48.322,260.319,0Z" transform="translate(-1.167 -580.385)" />
                    </svg>
                </div>
            </div>
        );
    }
}

export default props => (
    <StaticQuery
        query={graphql`
        {
            home: file(relativePath: {eq: "home.png"}) {
                childImageSharp{
                 fluid(maxWidth: 538) {
                    ...GatsbyImageSharpFluid_noBase64
                 }
               }
             }
           }
      `
        }
        render={data => (
            <YourData images={data} {...props} />
        )}
    />
)
