import React from 'react'
import { Layout, Row, Col, Button } from 'antd'
const { Footer } = Layout

// @ts-ignore
import styles from './joinus.module.scss';
import Signup from '../../Signup';
import { Link, StaticQuery, graphql } from 'gatsby';
import Img from "gatsby-image";

// @ts-ignore
import appstoreIcon from '../../../assets/appstore.svg'

// @ts-ignore
import googleplayIcon from '../../../assets/googleplay.svg'

import PhoneEmulatorV2, { PhoneEmulatorColor, PhoneEmulatorType } from '../../PhoneEmulatorV2';
import AppStoreButton from '../../Buttons/AppStoreButton';
import GooglePlayButton from '../../Buttons/GooglePlayButton';


interface JoinUsProps {
    moreButton: boolean;
}

export default class JoinUs extends React.Component<JoinUsProps, any> {

    constructor(props) {
        super(props);
        this.renderMoreButton = this.renderMoreButton.bind(this);
    }

    renderMoreButton() {
        if (this.props.moreButton) {
            return (
                <div>
                    <Link to="/bestill"><Button type="primary" size="large" className={styles.buttonLarge} {...{"aria-label": "Bestill Oss-brikke"}}>Bestill Oss-Brikke</Button></Link>
                </div>
            )
        }
    }

    render() {
        return (
            <StaticQuery
                query={graphql`
                {
                    boxImg: file(relativePath: {eq: "oss_box_v4@2x.png"}) {
                        childImageSharp{
                        fluid(maxWidth: 576) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                    }
                }
                `
                }
                render={data => (
                    <div className={styles.section}>
                        <Row className={styles.container} type="flex" justify="space-around" align="middle">
                            <Col xs={24} sm={24} md={24} lg={10}>
                                <div className={styles.header}>Vil du bli en av&nbsp;Oss?</div>
                                <div className={styles.introtext}>
                                    Fremtidens tjenester blir til ved at vi jobber sammen. Bestill en Oss-brikke i dag, og bli en av de første som mottar en brikke fra oss.   
                                        {this.renderMoreButton()}
                                </div>
                            </Col>
                            <Col xs={0} sm={24} md={24} lg={14} style={{}}>
                                <div className={styles.visuals}>
                                    <div className={styles.visualone}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 570.762 593.732" preserveAspectRatio="none">
                                            <path style={{ fill: "#00ff4a" }} d="M235.077,500.806c129.83,0,235.077-104.058,235.077-232.419S313.911,160.474,235.077,35.967,0,140.025,0,268.387,105.248,500.806,235.077,500.806Z" transform="matrix(0.974, 0.225, -0.225, 0.974, 112.657, 0)" />
                                        </svg>
                                    </div>
                                    <div className={styles.visualtwo}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 791.053 715.573" preserveAspectRatio="none">
                                            <path style={{ fill: "#ff495f" }} d="M336.76,0C517.291,0,663.641,145.958,663.641,326.007s-138.467,84.907-318.7,270.912S71.753,487.583,155.907,346.6,156.228,0,336.76,0Z" transform="matrix(-0.309, -0.951, 0.951, -0.309, 205.076, 834.065)" />
                                        </svg>
                                    </div>

                                    {/* <div className={styles.emulatorcontainer}>
                                        <PhoneEmulatorV2 color={PhoneEmulatorColor.Red} type={PhoneEmulatorType.Intro} />
                                    </div> */}
                                    <div className={styles.imagecontainer}>
                                        <Img title="Oss-brikken" fluid={data.boxImg.childImageSharp.fluid} alt="Oss brikken"/>
                                    </div>
                                                                        
                                </div>
                            </Col>
                        </Row>
                        <div className={styles.small}>
                            <div className={styles.visualssmall}>
                                {/* <div className={styles.visualonesmall}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 570.762 593.732" preserveAspectRatio="none">
                                        <path style={{ fill: "#00ff4a" }} d="M235.077,500.806c129.83,0,235.077-104.058,235.077-232.419S313.911,160.474,235.077,35.967,0,140.025,0,268.387,105.248,500.806,235.077,500.806Z" transform="matrix(0.974, 0.225, -0.225, 0.974, 112.657, 0)" />
                                    </svg>
                                </div>
                                <div className={styles.visualtwosmall}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 791.053 715.573" preserveAspectRatio="none">
                                        <path style={{ fill: "#ff495f" }} d="M336.76,0C517.291,0,663.641,145.958,663.641,326.007s-138.467,84.907-318.7,270.912S71.753,487.583,155.907,346.6,156.228,0,336.76,0Z" transform="matrix(-0.309, -0.951, 0.951, -0.309, 205.076, 834.065)" />
                                    </svg>
                                </div> */}
                                {/* <div className={styles.emulatorcontainersmall}>
                                    <PhoneEmulatorV2 color={PhoneEmulatorColor.Red} type={PhoneEmulatorType.Intro} />
                                </div> */}
                                <div className={styles.imagecontainersmall}>
                                    <Img title="Oss-brikken" fluid={data.boxImg.childImageSharp.fluid} alt="Oss brikken"/>
                                </div>
                            </div>
                            {/* <Row style={{ marginLeft: "auto", marginRight: "auto", textAlign: "center", maxWidth: 330 }} type="flex" justify="space-around" align="middle">
                                <Col xs={24} sm={12} style={{ marginTop: 0 }}><AppStoreButton /></Col>
                                <Col xs={24} sm={12} style={{ marginTop: 24 }}><GooglePlayButton /></Col>
                            </Row> */}
                        </div>
                        
                    </div>
                )}
            />
        )
    }
}