import React from 'react'
import { Layout, Row, Col, Button } from 'antd'
const { Footer } = Layout

// @ts-ignore
import styles from './reservebox.module.scss';

// @ts-ignore
import appstoreIcon from '../../../assets/appstore.svg'

// @ts-ignore
import googleplayIcon from '../../../assets/googleplay.svg'

import PhoneEmulatorV2, { PhoneEmulatorColor, PhoneEmulatorType } from '../../PhoneEmulatorV2';
import { Link, StaticQuery, graphql } from 'gatsby';
import Img from "gatsby-image";
import AppStoreButton from '../../Buttons/AppStoreButton';
import GooglePlayButton from '../../Buttons/GooglePlayButton';


export default () => (
    <StaticQuery
        query={graphql`
        {
            boxImg: file(relativePath: {eq: "oss_box_v4@2x.png"}) {
                childImageSharp{
                 fluid(maxWidth: 3000) {
                    ...GatsbyImageSharpFluid
                 }
               }
             }
           }
        `
        }
        render={data => (
            <div className={styles.section}>
                <div className={styles.introcontainer}>
                    <Row className={styles.container} justify="center">
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <div className={styles.header}>Strømforbruket ditt, rett på mobilen</div>
                            <div className={styles.introtext}>
                                Oss er en ny digital tjeneste, som gir deg full oversikt og kontroll over strømforbruket, uansett hvor du er.
                                <div>
                                    <Link to="/bestill"><Button type="default" size="large" className={styles.buttonLargeDefault} {...{"aria-label": "Les mer"}}>Bestill Oss-brikke</Button></Link>
                                </div>


                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <Img title="Oss-brikken" fluid={data.boxImg.childImageSharp.fluid} alt="Oss brikken"/>
                        </Col>
                    </Row>

                </div>
                <div style={{ marginTop: -1, float: "left", width: "100%", minWidth: 296 }}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920.42 181.6" preserveAspectRatio="none">
                        <path style={{ fill: "#007BFF" }} d="M0,0H1920.38L1920,23.76,412.05,22.92c-270.28-2,286.3,190.21-238.24,154.17C61.49,169.39-2,159.19,0,142.17Z" transform="translate(0.05)" />
                    </svg>
                </div>
            </div>
        )}
    />
)
