import React from 'react'
import { Layout, Row, Col, Button, AutoComplete } from 'antd'
import Img from "gatsby-image";

const { Footer } = Layout

// @ts-ignore
import redpin from '../../../assets/redpin.svg';

// @ts-ignore
import styles from './contactus.module.scss';
import { StaticQuery, graphql } from 'gatsby';

export default () => (
    <StaticQuery
        query={graphql`
        {
            map: file(relativePath: {eq: "map.png"}) {
                childImageSharp{
                 fluid(maxWidth: 2816) {
                    ...GatsbyImageSharpFluid
                 }
               }
             }
           }
      `
        }
        render={data => (
            <div className={styles.contactus}>
                <div className={styles.infocontainer}>
                    <div className={styles.container}>
                        <div className={styles.header}>Kontakt Oss</div>
                    </div>

                    <Row className={styles.container} justify="space-around" type="flex" align="bottom" style={{ textAlign: "center" }}>
                        <Col xs={24} sm={0} md={3}></Col>
                        <Col xs={24} sm={0} md={3}></Col>
                        <Col xs={24} sm={12} md={6}  style={{}}>
                            <div style={{ padding: 5, width: 40, height: 60, marginLeft: "auto", marginRight: "auto" }}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.723 44.924" >
                                    <g id="pin" transform="translate(-638.237 -9670.734)">
                                        <g transform="translate(30 11.084)">
                                            <g transform="translate(609.737 9661.15)">
                                                <path style={{ stroke: "#FFF", strokeWidth: 3, fill: "none" }} d="M15.36,0A15.215,15.215,0,0,0,0,15.36C.293,28.589,15.36,31.235,15.36,39.687c0,9.334,15.36-13.009,15.36-24.327A15.4,15.4,0,0,0,15.36,0Z" transform="translate(0.003)" />
                                                <path style={{ stroke: "#FFF", strokeWidth: 3, fill: "none" }} d="M18.309,25.217a6.909,6.909,0,1,1,6.908-6.909A6.9,6.9,0,0,1,18.309,25.217Z" transform="translate(-3.019 -3.022)" />
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <div>Grønland 67, 3045 Drammen</div>
                        </Col>
                        <Col xs={24} sm={12} md={6}>
                            <div style={{ paddingTop: 12, width: 40, height: 60, marginLeft: "auto", marginRight: "auto" }}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.491 30.429">
                                    <g id="email" transform="translate(0)">
                                        <path style={{ fill: "#FFF" }} d="M607.7,1319h40.491v30.429H607.7Zm5.364,2.768-.557.675q7.07,6.858,14.156,13.709c1.122,1.082,2.164.674,3.121-.29q2.717-2.729,5.435-5.451c2.729-2.76,5.451-5.529,8.517-8.635C633.134,1321.768,623.1,1321.768,613.064,1321.768Zm29.943,24.932c-3.466-3.427-6.776-6.705-10.086-9.991-4.306,3.9-4.768,3.9-9.443.345-3.529,3.286-6.956,6.486-10.344,9.646Zm-32.6-22.218v19.277c3.3-3.059,6.737-6.243,10.031-9.293C617,1331.054,613.707,1327.768,610.406,1324.482Zm35.111,19.308V1324.8c-3.231,3.247-6.447,6.478-9.89,9.929C638.827,1337.665,642.207,1340.763,645.517,1343.79Z" transform="translate(-607.7 -1319)" />
                                    </g>
                                </svg>
                            </div>
                            <div>hei@oss.no</div>
                        </Col>
                        <Col xs={24} sm={0} md={3} ></Col>
                        <Col xs={24} sm={0} md={3} ></Col>
                    </Row>
                </div>
                <div style={{position: "relative", width: "auto", height: "auto"}}>
                <a href="https://www.google.com/maps/place/Grønland+67,+3045+Drammen/" target="_new">
                    <Img title="Kontakt oss" fluid={data.map.childImageSharp.fluid} style={{ minHeight: 400, maxHeight: 400 }} alt="Kart med prikk" />
                    <div style={{ position: "absolute", zIndex: 99, marginTop: -260, left: "50%", marginLeft: -40, height: 80, width: 80 }}>
                        <img src={redpin} alt="Der vi er på kartet" />
                    </div>
                    </a>
                </div>
            </div>
        )}
    />
)

