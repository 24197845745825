import React from 'react'
import { Row, Col, Button } from 'antd'
import Img from "gatsby-image";

// @ts-ignore
import styles from './pitch.module.scss';

import { StaticQuery, graphql, Link } from 'gatsby';

export default () => (
    <StaticQuery
        query={graphql`
        {
            pitch: file(relativePath: {eq: "Oss-testbruker.png"}) {
                childImageSharp{
                 fluid(maxWidth: 680) {
                    ...GatsbyImageSharpFluid_noBase64
                 }
               }
             }
           }
      `
        }
        render={data => (
            <div className={styles.section}>
                <Row className={styles.container}>
                        <Col xs={24} sm={24} lg={7}>
                            <div className={styles.imagecontainer}>
                                <Img title="Oss-Testbruker" fluid={data.pitch.childImageSharp.fluid} className={styles.image} alt="Oss-Testbruker illustrasjon" />
                            </div>
                        </Col>
                        <Col xs={24} sm={24} lg={17}>
                            <div className={styles.header}>Med Oss får du...</div>
                            <Row type="flex" align="top" gutter={24}>
                                <Col xs={24} sm={12}>
                                    <div className={styles.subheader}>Se strømforbruket ditt akkurat nå</div>
                                    <div className={styles.text}>Hvor mye strøm bruker du akkurat nå? For folk flest er det helt gresk. Dette gir Oss deg muligheten til å se&nbsp;nå.</div>
                                </Col>
                                <Col xs={24} sm={12}>
                                    <div className={styles.subheader}>Se ditt historiske strømforbruk</div>
                                    <div className={styles.text}>Spol tilbake i tid og se ditt historiske strømforbruk. Hvor mye strøm brukte du i&nbsp;fjor?</div>
                                </Col>
                                <Col xs={24} sm={12}>
                                    <div className={styles.subheader}>Påvirke ditt strømforbruk</div>
                                    <div className={styles.text}>Oss-Brikken og Oss-Appen gir deg tilgang til informasjonen fra strømmåleren og oversetter den til forståelig&nbsp;data.</div>
                                </Col>
                                <Col xs={24} sm={12}>
                                    <div className={styles.subheader}>Trygg 4G dekning fra Telenor</div>
                                    <div className={styles.text}>Målet er på relativt kort sikt å være på plass i alle sikringsskap i norske hjem. Eksepsjonelt god dekning er derfor helt essensielt for&nbsp;oss.</div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={24} sm={24} lg={12}>
                                    <div className={styles.buttoncontainer}><Link to="/tjenester"><Button type="primary" size="large" className={styles.buttonLarge} {...{"aria-label": "Les mer"}}>Les mer</Button></Link></div>
                                </Col>
                            </Row>
                        </Col>
                </Row>
        </div>
        )}
    />
)
