import React from "react"
import { Helmet } from "react-helmet"
import SiteLayout from "../components/SiteLayout";
import Intro from "../components/Sections/Intro";
import HowItWorks from "../components/Sections/HowItWorks";
import Pitch from "../components/Sections/Pitch";
import Team from "../components/Sections/Team";
import JoinUs from "../components/Sections/JoinUs";
import YourData from "../components/Sections/YourData";
import NerdMode from "../components/Sections/NerdMode";
import ContactUs from "../components/Sections/ContactUs";
import BlogFeed from "../components/Sections/BlogFeed";
import ReserveBox from "../components/Sections/ReserveBox";

export default ({ children }) => (
    <SiteLayout>
        {/* <Intro /> */}
        <ReserveBox />
        <Pitch />
        <HowItWorks />
        <JoinUs  moreButton={true} />
        <YourData topVisual={true} moreButton={true} />
        <NerdMode />
        {/* <Team /> */}
        <ContactUs />
    </SiteLayout>
)
