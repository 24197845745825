import React from 'react'
import { Layout, Row, Col, Button } from 'antd'
const { Footer } = Layout
import Img from "gatsby-image";

// @ts-ignore
import styles from './howitworks.module.scss';
// @ts-ignore
import ossbrikken from '../../../assets/ossbrikken.png';
import { StaticQuery, graphql, Link } from 'gatsby';

export default () => (
    <StaticQuery
        query={graphql`
        {
            howitWorksImg: file(relativePath: {eq: "ossbrikken.png"}) {
                childImageSharp{
                 fluid(maxWidth: 894) {
                    ...GatsbyImageSharpFluid
                 }
               }
             }
           }
      `
        }
        render={data => (
            <div className={styles.section}>
                <div style={{ marginBottom: -10, minWidth: 296 }}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920.001 180.719" preserveAspectRatio="none">
                        <path style={{ fill: "#FF495F" }} d="M7733,6127.42H5813v-45.933c243.648,14.01,443.438,21.114,593.82,21.114,48.706,0,92.809-.756,131.085-2.246,40.471-1.576,75.531-4.017,104.206-7.254,14.91-1.684,28.4-3.617,40.1-5.747a275.357,275.357,0,0,0,31.071-7.289c8.83-2.716,15.988-5.719,21.274-8.926a36.216,36.216,0,0,0,6.722-5.108,18.686,18.686,0,0,0,3.984-5.554,13.363,13.363,0,0,0,.6-9.318,27.358,27.358,0,0,0-6.281-10.417,79.089,79.089,0,0,1-8.934-10.19,42.615,42.615,0,0,1-5.28-9.479,25.327,25.327,0,0,1-1.783-8.792,20.869,20.869,0,0,1,1.557-8.125,27.164,27.164,0,0,1,5.163-7.954,47.46,47.46,0,0,1,8.594-7.248c6.676-4.5,15.666-8.7,26.72-12.486a309.783,309.783,0,0,1,38.164-9.981c14.1-2.86,30.283-5.448,48.094-7.689,33.376-4.2,73.719-7.349,119.907-9.355,41.856-1.818,89.438-2.74,141.425-2.74,61.875,0,131.2,1.3,206.046,3.864,60.831,2.083,125.637,5.01,192.619,8.7,117.077,6.446,204.746,13.235,221.131,14.531v153.625Z" transform="translate(-5813 -5946.701)" />
                    </svg>
                </div>
                <div className={styles.howitworkscontainer}>
                    <div className={styles.container}>
                        <Row align="bottom" type="flex" justify="space-around">
                            <Col xs={24} sm={24} md={24} lg={13} xl={14}>
                                <div className={styles.header}>Hvordan virker det egentlig?</div>
                                <div className={styles.introtext}>
                                Oss-Brikken kommuniserer med IoT teknologi til plattformen vår og videre til Oss-Appen som gir deg verdifulle data og innsikt i strømforbruket i hjemmet&nbsp;ditt.
                                        <div>
                                        <Link to="/tjenester"><Button type="default" size="large" className={styles.buttonLargeDefault} {...{"aria-label": "Les mer"}}>Les mer</Button></Link>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={11} xl={10}>
                                <div className={styles.imagecontainer}>
                                    <Img title="Oss-Team" fluid={data.howitWorksImg.childImageSharp.fluid} className={styles.image} alt="Oss Teamet" />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        )}
    />
)
