import React from 'react'
import { Row, Col, Button } from 'antd'
import { Link } from 'gatsby';

// @ts-ignore
import styles from './nerdmode.module.scss';

// @ts-ignore
import gifbrikken from '../../../assets/nerdmode-min.gif';

export default () => (
    <div className={styles.section}>
        <Row className={styles.container} justify="space-around" align="middle" type="flex">
            <Col xs={24} sm={24} md={24} lg={12}>
                <div className={styles.header}>#nerdemodus</div>
                <div className={styles.introtext}>
                    Oss-funksjonaliteten som lar deg dykke enda dypere inn i strømmåleren&nbsp;din.
                            <div><Link to="/nerdemodus"><Button type="primary" size="large" className={styles.buttonLarge} {...{"aria-label": "Les mer"}}>Les mer</Button></Link></div>
                </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12}>
                <div className={styles.gifcontainer}>
                    <img src={gifbrikken} className={styles.gif} alt="Oss-Brikken" />
                </div>
            </Col>
        </Row>
    </div>
)